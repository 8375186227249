<h4><i class="fas fa-comment"></i> Presenting Complaints</h4>
<hr>
<ng-container *ngIf="complaintPresent">
	<table class="table table-responsive borderless" *ngFor="let complaint of complaint">
		<tr>
			<div [innerHTML]='complaint.value'></div>
		</tr>
	</table>
<div>
	<small><i>c. = Associated with, s. = Not associated with, h/o = History of </i></small>
</div>
</ng-container>
