<div class="container">
<div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                   
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="design">
                            <a href="https://www.intelehealth.org/"><img class="navbar-brand" src="../../../../assets/images/intelehealth-logo-reverse.png" width="100" height="50"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>