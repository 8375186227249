<mat-dialog-content>
  <div class="list-group">
    <div *ngFor='let value of values'>
      <div class="list-group-item ">
        <span class="patient-identifier">
          <span class="identifier-type">{{value.identifiers[0].identifierType.name}}</span>
          <span class="identifier">{{value.identifiers[0].identifier}}</span>
        </span>
        <span class="preferred-name">{{ value.person.display}}</span>
        <span class="gender">{{ value.person.gender}}, </span>
        <span class="age">{{ value.person.age}}</span>
        <button style="float: right" class="btn btn-primary" (click)="find(value.uuid)">View</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div *ngIf="values.length == 0">
  <h3> {{msg}}</h3>
</div>
