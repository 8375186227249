<div>
  <h2 style="text-align:center;">Visit Summary</h2>
</div>  
<mat-spinner *ngIf="setSpiner" [diameter]="70"></mat-spinner>

<div class="container" [ngClass]="(setSpiner)?'spin':''">
      <mat-card>
            <app-patientinfo></app-patientinfo>
      </mat-card>
<br>
      <mat-card >
            <app-past-visits></app-past-visits>
      </mat-card>
<br>
      <mat-card>
            <app-current-visit></app-current-visit>
      </mat-card>
<br>
      <mat-card>
            <app-presenting-complaints></app-presenting-complaints>
      </mat-card>
<br>
      <mat-card>
            <app-family-history></app-family-history>
      </mat-card>
<br>
      <mat-card>
            <app-past-medical-history></app-past-medical-history>    
      </mat-card>
<br>
      <mat-card >
            <app-vital></app-vital>
      </mat-card>
<br>
      <mat-card>
            <app-on-examination></app-on-examination>
      </mat-card>
<br>
      <mat-card>
            <app-physical-examination></app-physical-examination>
      </mat-card>
<br>
      <mat-card>
            <app-additional-documents></app-additional-documents>
      </mat-card>
<br>


<mat-card>
<small style="color: red">
  <i>
  <b>Note:</b> 
    This history note and physical exam note was generated by a community health worker with the support
    of the Intelehealth mobile application. It collects only preliminary findings and may not gather all of the patient's
    clinical information, especially sensitive information or complex physical exam information which is hard for the
    health worker to collect. Please verify crucial clinical information and collect any additional information you
    require by speaking with the patient directly. 
  </i>
</small>
</mat-card>
<br>

  
  <div class="text-center" *ngIf="!show">
    <button (click)="onStartVisit()" id="start-visit" mat-raised-button color="primary">Start Visit Note</button>
  </div>

  <br>

  <div *ngIf="show"> 
      <mat-card>
            <app-patient-interaction></app-patient-interaction>
      </mat-card>
<br>
      <mat-card >  
            <app-diagnosis></app-diagnosis>
      </mat-card>
<br>
      <mat-card>
            <app-prescribed-medication></app-prescribed-medication>
      </mat-card>
<br>
      <mat-card>
            <app-prescribed-test></app-prescribed-test>
      </mat-card>
<br>
      <mat-card>
            <app-advice></app-advice>
      </mat-card>
<br>
      <mat-card>
            <app-additional-comment></app-additional-comment>
      </mat-card>
<br>
      <mat-card>
          <app-follow-up></app-follow-up>
      </mat-card>
<br>
      <ng-container *ngIf="visitCompletePresent">
            <div class="d-flex justify-content-center">
                  <mat-card>
                             <div [style.font-family]="font" style="font-size: 50px">{{text}}</div>
                  </mat-card>    
            </div>
            <br>
      </ng-container>

      <div class="text-center">
            <button (click)="sign()" id="sign-button" mat-raised-button color="primary" [disabled]="visitCompletePresent">Sign and Submit</button>
      </div>
</div>
</div>




