<h4><i class="material-icons">contact_phone</i> Patient Interaction</h4>
<hr>
<div>
  Have you spoken with the patient directly?
</div>
<form [formGroup]='interaction' (ngSubmit)='submit()'>
<div id="patient-interaction">
    <br>
        <mat-radio-group aria-label="Select an option" formControlName="interaction">
            <mat-radio-button value="Yes">Yes </mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
          </mat-radio-group>&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="submit" mat-raised-button color="primary" [disabled]="!interaction.valid" [hidden]="msg.length > 0">Submit</button>
</div>
<div id="call-link" *ngIf="whatsappLink || phoneNo"> Start a call: 
  <span *ngIf="whatsappLink"><a class="fab fa-whatsapp" href="{{whatsappLink}}" target="_blank" ></a></span>
  <span *ngIf="phoneNo"><a href="tel:{{phoneNo}}" class="fas fa-phone-volume"></a></span>
</div>
<div id="call-link" *ngIf="!whatsappLink && !phoneNo"> 
  Please provide health worker's contact number at <a href="mailto:support@intelehealth.io">support@intelehealth.io</a> to proceed with the call or message 
  <br/>
</div>
<div *ngFor="let msg of msg">  
  <div [@moveInLeft] class="alert alert-primary">{{msg}}</div>
</div>
</form>