<div class="card">
    <div class="card-body">
      <h4 class="card-title">Change Password <a style="float: right; cursor: pointer;" (click)="onClose()" class="material-icons">clear</a></h4>
        <form [formGroup]="changePasswordForm" (submit)="onSubmit()">
            <mat-form-field class="example-full-width">
                <input matInput type="password" placeholder="Current Password" formControlName="currentPassword">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput type="password" placeholder="New Password (Min 8 alphabet)" formControlName="newPassword">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput type="password" placeholder="Repeat Password (Min 8 alphabet)" formControlName="repeatPassword">
            </mat-form-field>
            <mat-dialog-actions align="end">
              <button mat-raised-button color="primary" type="submit" [disabled]="!changePasswordForm.valid">Submit</button>           
            </mat-dialog-actions>
          <br>
          <div *ngIf="message != null" [ngClass]="message === 'Success'? 'alert alert-success': 'alert alert-danger'">
          <h5>{{message}}</h5>
          </div>
        </form>
    </div>
</div>