<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
        <div class="mx-auto mb-3" style="text-align: center"><img src="../../../assets/images/Intelehealth-logo-white-transp.png" height="100" width="230"></div>
      <div class="modal-dialog w-100 mx-auto">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome back,</div>
                <span>Please sign in to your account below.</span></h4>
            </div>
            
            <div role="group" class="form-group">
              <div>
                  <label for="examplePassword">Username :</label>
                <input type="email" placeholder="Enter Username..." class="form-control" formControlName="username">
              </div>
            </div>
            <div role="group" class="form-group">
              <div>
                  <label for="examplePassword">Password :</label>
                <input type="password" placeholder="Enter password..." class="form-control" formControlName="password">
              </div>
            </div>
           
          
            </div>
          <div class="modal-footer clearfix">
            <div class="float-right">
              <button type="submit" class="btn btn-primary" [disabled]='!loginForm.valid'>Login to Dashboard</button>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>
