<div class="container">
  <mat-card>
    <div class="upload">
      <div><b>Select licence Key: </b> &nbsp;&nbsp;
        <mat-form-field>
          <mat-label>MindMap Key</mat-label>
          <mat-select [(ngModel)]="selectedKey" (selectionChange)="licenceKeyHandler()">
            <mat-option *ngFor="let mindmap of mindmaps" [value]="mindmap.keys">
              {{ mindmap.keys }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addKey()" style="float: right;">Add New Key</button>
      </div>
      <div *ngIf="selectedKey">
        <div>Expiry Date: {{expiryDate | date: 'dd-MMM-yyyy'}}
          <span>
            <i class="material-icons" style="cursor:pointer;vertical-align: text-bottom;" (click)="editExpiryDate()">create</i>
          </span>
        </div>
        <br>
        <div>Mindmaps associated with this licence key are :
          <span style="float: right;">
            <button mat-raised-button color="primary" (click)="addMindmap()" style="float: right;">Add Mindmap</button>
          </span>
        </div>
        <br>
        <mat-form-field class="search-text">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
        </mat-form-field>
        
        <div class="mat-elevation-z8">
          <table class="table-responsive" mat-table [dataSource]="dataSource" #sortCol4="matSort" matSort>
        
            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>No. </th>
              <td mat-cell *matCellDef="let row; let i = index;">{{i + (paginator.pageIndex * paginator.pageSize) + 1}}</td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row">{{row.name}} </td>
            </ng-container>
        
            <!-- Last Updated Column -->
            <ng-container matColumnDef="lastUpdate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
              <td mat-cell *matCellDef="let row">{{row.last_update | date: 'dd-MMM-yyyy'}} </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let row; let i = index;">
                <!-- <i class="material-icons" style="cursor:pointer;padding-right:6px;" (click)="editMindmap(i + (paginator.pageIndex * paginator.pageSize))">create</i> -->
                <i class="material-icons" style="color:rgb(235, 30, 30);cursor: pointer;" (click)="deleteMindmap(row.name)">delete</i>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayColumns" id="columns-sorting"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;">
            </tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
        </div>
      </div>
      <br>
      <br>
      <div class="image-card" *ngIf="image">
        <div>Images associated with this licence key.</div>
        <br>
        <div><img src={{image.image_file}} /></div>
        <br>
        <button mat-raised-button color="primary" (click)="uploadDocument()">Replace Image</button>
        <br>
        <br>
        <div class="image-card">
          <button mat-button color="primary" (click)="imageUpdate()" class="submit"><mat-icon>file_upload</mat-icon>Upload</button>
        </div>
      </div>
      <div class="image-card" *ngIf="selectedKey && image === undefined">
        <button mat-raised-button color="primary" (click)="uploadDocument()">Add Image</button>
        <br>
        <br>
        <div class="image-card">
          <button mat-button color="primary" (click)="imageUpload()" class="submit"><mat-icon>file_upload</mat-icon>Upload</button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<input
  type="file"
  id="fileUpload"
  style="display:none;"
  (change)="fileHandler($event)"
/>


