<h4><i class="fas fa-comment-medical"></i> Advice</h4>
<hr>
<div id="advice-form">
  <form [formGroup]='adviceForm'>
      <mat-form-field class="example-full-width">
          <input matInput placeholder="Advice or referal for patient" [ngbTypeahead]="search" formControlName='advice'>
      </mat-form-field>
      &nbsp;&nbsp;
      <button mat-raised-button color="primary" type="reset" (click)="submit()" [disabled]='!adviceForm.valid'>Add Advice</button>
  </form>
</div>
<ng-container *ngIf="advice.length > 0">
  <div>
  <ul class="list-unstyled">
  <mat-list [@moveInLeft] *ngFor='let advice of advice, index as i' class="alert alert-primary">
    <div class="doctor-value">{{advice.value}}</div>
    <i (click)="delete(i)" class="material-icons float-right" style="color:rgb(235, 30, 30);cursor: pointer;">delete</i>
  </mat-list>
  </ul>
</div>
</ng-container>

