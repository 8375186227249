<h4><i class="fas fa-info-circle"></i> Patient Information</h4>
<div class="modal-body row">
        <div class="col-md-6">
          <table class="table table-borderless table-sm">
                <tbody *ngFor="let info of patientInfo">
                    <tr>
                        <th scope="row">Name</th>
                        <td>{{info.display}}</td>
                    </tr>
                    <tr>
                        <th scope="row">OpenMRS ID</th>
                        <td>{{patientIdentifier}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Sex</th>
                        <td>{{info.gender}}</td>
                    </tr>
                    <tr>
                        <th scope="row">DOB</th>
                        <td>{{info.birthdate | date: 'dd-MMM-yyy'}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Village</th>
                        <td>{{info.preferredAddress.cityVillage}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Contact Info</th>
                        <td *ngIf="info.telephone">{{info.telephone}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
          <div *ngIf="profileImagePresent" style="text-align: center">
                <img [src]= image width="40%" height="200">
            </div>
            <div *ngIf="!profileImagePresent" style="text-align: center">
                <img src="../../../../assets/dummy profile image.jpg" width="40%" height="40%">
            </div>
        </div>
</div>