<h4><i class="fas fa-pills"></i> Prescribed Medication</h4>
<hr>
<div>
  <form [formGroup]="medForm">
        <mat-form-field class="example-full-width" id="medication-form">
            <input matInput placeholder="Medication" (keydown)="add = true"  [ngbTypeahead]="searchPrescription" formControlName="med">
        </mat-form-field>
        &nbsp;&nbsp;
    <button type="reset" (click)="onSubmit()" mat-raised-button color="primary" [disabled]='!medForm.valid'>Add Meds</button>
  <div *ngIf="add">
    <div class="form-row">
      <div class="col">
        <input type="number" min="1" class="form-control" placeholder="Strength" formControlName="dose">
      </div>
      <div class="col">
        <input type="text" class="form-control" [ngbTypeahead]="searchDose" placeholder="Units (eg: mg)" formControlName="unit">
      </div>
      <div class="col">
        <input type="number" min="1" class="form-control" placeholder="Amount" formControlName="amount">
      </div>
      <div class="col">
        <input type="text" class="form-control" [ngbTypeahead]="searchDose" placeholder="Units (eg: Tab/Syrup/etc.)" formControlName="unitType">
      </div>
      <div class="col">
        <input type="text" class="form-control" [ngbTypeahead]="searchFrequency" placeholder="Frequency" formControlName="frequency">
      </div>
      <div class="col">
        <input type="text" class="form-control" [ngbTypeahead]="searchAdministration" placeholder="Route(optional)" formControlName="route">
      </div>
    </div>
    <br>
        <div>As needed</div>
        <div class="form-row">
          <div class="col">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">For</span> 
              <input type="number" min="1" class="form-control" placeholder="Duration" formControlName="duration">
            </div>
          </div>
          <div class="col">
            <div class="input-group-prepend">
              <input type="text" class="form-control" [ngbTypeahead]="durationUnit" placeholder="Units(eg: Days/Weeks/etc.)" formControlName="durationUnit">              
              <span class="input-group-text" id="basic-addon1">total</span>
            </div>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="reason(optional)" formControlName="reason"> 
          </div>
        </div>
    <br>
    <textarea class="form-control" placeholder="Additional instruction not covered above" formControlName="additional"></textarea>
    <br>
  </div>
</form>
</div>
<ng-container *ngIf="meds.length > 0">
    <ul class="list-unstyled">
      <mat-list [@moveInLeft] *ngFor='let meds of meds, index as i' class="alert alert-primary" style="margin: 1px;">
        <div class="doctor-value">{{meds.value}}</div>
        <i (click)="delete(i)" class="material-icons float-right" style="color:rgb(235, 30, 30);cursor: pointer;">delete</i>
      </mat-list>
    </ul>
</ng-container>