<h4>Update Details <a style="float: right; cursor: pointer;" (click)="onClose()" class="material-icons">clear</a></h4>    
<form [formGroup]='editForm' (submit)="updateDetails()">
    <mat-form-field class="example-full-width">
        <mat-select placeholder="Gender" formControlName="gender">
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Phone No" type="number" formControlName="phoneNumber">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="WhatsApp No" formControlName="whatsapp">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Email Id" type="email" formControlName="emailId">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Qualification" formControlName="qualification">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Specialization" formControlName="specialization">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Registration No" formControlName="registrationNumber">
    </mat-form-field>
    <div style="float: right">
    <button mat-raised-button color="primary" type="submit">Save</button>
    </div>
</form>
