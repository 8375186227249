<h4><i class="fas fa-stethoscope"></i> On Examination</h4>
<hr>
<ng-container *ngIf="onExamPresent">
    <table class="table table-responsive borderless" *ngFor="let onExam of onExam">
        <tr>
            <div [innerHTML]=onExam.value></div>
        </tr>
    </table>
</ng-container>

