<h4><i class="fas fa-book"></i> Follow Up</h4>
<hr>
<div>
<form [formGroup]="followForm">
<div id="followup-button">
  <p>
    <mat-form-field>
      <input matInput readonly [matDatepicker]="dp3" [min]="minDate" placeholder="Choose a date" formControlName='date'>
      <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
      <mat-datepicker #dp3 disabled="false"></mat-datepicker>
    </mat-form-field>
  </p>
      <mat-form-field class="example-full-width">
        <textarea matInput placeholder="Follow up remark" formControlName='advice'></textarea>
      </mat-form-field>
<button type="reset" (click)="Submit()" mat-raised-button color="primary" [disabled]="!followForm.valid" [hidden]='followUp.length > 0'>Schedule a follow up</button>
</div>
{{errorText}}
</form>
<ng-container *ngIf="followUp.length > 0">
    <ul class="list-unstyled">
    <mat-list [@moveInLeft] *ngFor='let followUp of followUp, index as i' class="alert alert-primary">
        <div class="doctor-value">{{followUp.value}}</div>
        <i (click)="delete(i)" class="material-icons float-right" style="color:rgb(235, 30, 30);cursor: pointer;">delete</i>
    </mat-list>
    </ul>
    <p>*please delete current schedule to schedule a new follow up.</p>
</ng-container>
</div>
