<mat-spinner *ngIf="setSpiner" [diameter]="70"></mat-spinner>
<ng-container *ngIf="!setSpiner">
        <div class="container">
                    <div class="wrap-login100">
                        <div class="login100-form-title">
                                <img class="login100-form-avatar" src='../../../assets/dummy profile image.jpg' width="25%">  
                                <span *ngIf=providerDetails.person.display>{{providerDetails.person.display}}</span>
                                <span *ngIf=providerDetails.address>{{providerDetails.address.value}}</span>
                        </div>
                        <mat-card class="overlap">
                        <div >
                            <i class="material-icons" style="float:right; cursor:pointer;" (click)="onEdit()">create</i>
                        
                            <table >
                                <tr>
                                    <th>Gender :</th>
                                    <td *ngIf='providerDetails.person'>{{providerDetails.person.gender}}</td>
                                </tr>
                                <tr>
                                    <th>Phone No. :</th>
                                    <td *ngIf='providerDetails.phoneNumber'>{{providerDetails.phoneNumber.value}}</td>
                                </tr>
                                <tr>
                                    <th>WhatsApp No. :</th>
                                    <td *ngIf='providerDetails.whatsapp'>{{providerDetails.whatsapp.value}}</td>
                                </tr>
                                <tr>
                                    <th>Email Id :</th>
                                    <td *ngIf='providerDetails.emailId'>{{providerDetails.emailId.value}}</td>
                                </tr>
                                <tr>
                                    <th>Qualification :</th>
                                    <td *ngIf='providerDetails.qualification'>{{providerDetails.qualification.value}}</td>
                                </tr>
                                <tr>
                                    <th>Specialization :</th>
                                    <td *ngIf='providerDetails.specialization'>{{providerDetails.specialization.value}}</td>
                                </tr>
                                <tr>
                                    <th>Registration No. :</th>
                                    <td *ngIf='providerDetails.registrationNumber'>{{providerDetails.registrationNumber.value}}</td>
                                </tr>
                                <tr>
                                    <th>Signature :</th>
                                    <td *ngIf='providerDetails.textOfSign' [style.font-family]="providerDetails.fontOfSign.value" style="font-size: 50px">{{providerDetails.textOfSign.value}}</td>
                                    <td *ngIf='!providerDetails.textOfSign'><button mat-raised-button color="primary" (click)="signature()">Signature</button></td>
                                </tr>
                            </table>
                        </div>
                        </mat-card>
                    </div>
        </div>
    </ng-container>